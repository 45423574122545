
export const products = [
    {
        id: 1,
        image: 'assets/pdpIcons/icon-pdp-1.png',
        title: 'products.product-1-title',
        description: 'products.product-1-description',
        specifications: {
            regulatory: 'GOST 618-2014',
            alloy: '1200, 8079, 8011 / 8011 A, 8006, 3003 M / 3003 T',
            thickness: 'From 0.007 to 0.200 mm',
            width: 'From 10 to 1500 mm',
            innerDiameter: '76.2; 150; 152.4',
            outerDiameter: '950 mm',
            surface: 'Glossy / Matte'
        }
    },
    {
        id: 2,
        image: 'assets/pdpIcons/icon-pdp-2.png',
        title: 'products.product-2-title',
        description: 'products.product-2-description',
        specifications: {
            regulatory: 'GOST 13726',
            alloy: '1200,  8079,  8011 / 8011 A,  8006,  3003 M / 3003 T',
            thickness: 'From 0.1500 to 0.200 mm',
            width: 'From 1.200 to 1.500 mm',
            innerDiameter: '500; 600;',
            outerDiameter: '1600 mm',
            surface: 'Glossy'
        }
    },
    {
        id: 3,
        image: 'assets/pdpIcons/icon-pdp-3.png',
        title: 'products.product-3-title',
        description: 'products.product-3-description',
        specifications: {
            regulatory: 'GOST 745-2014',
            alloy: '1200,  8079,  8011 / 8011 A,  8006,  3003 M / 3003 T',
            thickness: 'From 0.007 to 0.240 mm',
            width: 'From 15 to 1500 mm',
            innerDiameter: '76.2;  150;  152.4',
            outerDiameter: '950 mm',
            surface: 'Glossy / Matte'
        }
    },
    {
        id: 4,
        image: 'assets/pdpIcons/icon-pdp-4.png',
        title: 'products.product-4-title',
        description: 'products.product-4-description',
        specifications: {
            regulatory: 'SP-UD-household-export-2020',
            alloy: '1200,  8079,  8011 / 8011 A,  8006',
            thickness: 'From 0.025 to 0.07 mm',
            width: 'From 15 to 1500 mm',
            innerDiameter: '76.2;  150;  152.4',
            outerDiameter: '950 mm',
            surface: 'Glossy / Matte'
        }
    },
    {
        id: 5,
        image: 'assets/pdpIcons/icon-pdp-5.png',
        title: 'products.product-5-title',
        description: 'products.product-5-description',
        specifications: {
            regulatory: 'GOST 13726',
            alloy: '1200,  8079,  8011 / 8011 A,  8006,   3003 M / 3003 T',
            thickness: 'From 0.25 to 8.5 mm',
            width: 'From 40 to 1500 mm',
            innerDiameter: '500;  600',
            outerDiameter: '1600 mm',
            surface: 'Glossy'
        }
    },
    {
        id: 6,
        image: 'assets/pdpIcons/icon-pdp-6.png',
        title: 'products.product-6-title',
        description: 'products.product-6-description',
        specifications: {
            regulatory: 'SP-UD-household-export-2020 TU 24.42.25–019–14504589–2020',
            alloy: '3003 M / 3003 T / 8006',
            thickness: 'From 0.010 to 0.035 mm',
            width: 'From 40 to 1500 mm',
            innerDiameter: '76.2;  150;  152.4',
            outerDiameter: '950 mm',
            surface: 'Glossy'
        }
    }
    
]