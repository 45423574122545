<template>
  <section id="press">
    <div class="container">
      <h2 class="block-title title-white">{{$t('press-main-title')}}</h2>
      <div class="block-grid-3">
        <div class="blog-item blog-item-1" v-for="pressItem of pressItems">
          <img :src="imagePath(pressItem.imagePath)" alt="blog-image-1">
          <span class="blog-data">{{ moment(pressItem.updatedAt).format('Y-MM-D') }}</span>
          <h4 v-html="pressItem.title[locale]" />
          <p v-html="truncateText(pressItem.text[locale], 300)"/>
          <router-link :to="{name: 'press.single', params: {id: pressItem.id}}" class="simple-link">{{$t('read-more')}}
          </router-link>
        </div>
      </div>
      <a href="javascript:;" class="show-more-btn btn btn-solid">{{$t('read-more')}}</a>
    </div>
  </section>
</template>

<script>
import SingleNews from "@/views/SingleNews.vue";
import moment from 'moment'

export default {
  components: {SingleNews},
  computed: {
    locale() {
      const lang = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1')
      if (lang === 'am' || lang === 'ru' || lang === 'en') {
        return lang
      } else {
        return 'am'
      }
    },
  },
  data() {
    return {
      pressItems: []
    }
  },
  methods: {
    imagePath(path) {
      return `${process.env.VUE_APP_API_URL}/${path}`
    },
    moment(dateTime) {
      return moment(dateTime)
    },
    truncateText(text, limit) {
      if (text && text.length <= limit) {
        return text;
      } else if(text ){
        return text.slice(0, limit) + " ...";
      } else {
        return 
      }
    },
  },
  created() {
    this.axios.get('blog').then(res => {
      console.log(res.data);
      this.pressItems = res.data.reverse();
    })
  }
}
</script>

<style>
#press {
  position: relative;
  padding-top: 120px;
}

#press:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, #1E6091 0%,
  rgba(50, 120, 145, 0.8) 20%,
  rgba(61, 132, 146, 0.6) 40%,
  rgba(73, 147, 146, 0.4) 60%,
  rgba(96, 174, 147, 0.2) 80%,
  rgba(118, 200, 147, 0) 100%);
  z-index: -1;
}

#press .block-grid-3 {
  margin-top: 80px;
}

</style>
