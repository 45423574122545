<template>
  <section id="product">
    <div class="product-hero">
      <div class="container">
        <img src="../assets/images/product-hero.png" alt="product-hero">
        <h2 class="title-big text-center">{{ $t('product') }}</h2>
      </div>
    </div>
    <div class="product-content">
      <div class="container">
        <div class="pdp-content-grid block-grid-2">
          <p>{{ $t('products.content-2') }}</p>
            <img src="../assets/images/pdp-img1.png" alt="foil">
            <img src="../assets/images/pdp-img2.png" alt="foil">
            <p>{{ $t('products.content-3') }}</p>
        </div>
      </div>
    </div>
    <template>
      <div v-for="product of products" v-bind:key="product.id">
        <ProductItem :product="product" @open-modal="openModal"/>
      </div>
    </template>
  
    <CalcVue />
  <ProductOrderForm  v-if="isPopupOpen" @close-modal="closeModal"/>
  </section>
</template>

<script>
import { products } from '@/products'
import ProductItem from '@/components/blocks/productItems/ProductItem.vue';

import CalcVue from '@/components/blocks/Calc.vue';
import ProductOrderForm from '@/components/blocks/productItems/ProductOrderForm.vue'
export default {
  components: {
    CalcVue,
    ProductItem,
    ProductOrderForm,
  },
  data() {
    return {
      isPopupOpen: false,
      products: products,
      productTitle: '',
    };
  },
  methods: {
    openModal(productTitle) {
      this.isPopupOpen = true;
      this.productTitle = productTitle
    },
    closeModal() {
      this.isPopupOpen = false
    }
  },
 }

</script>

<style>
.product-item {
  margin-top: 160px;
}
  .product-content{
    padding: 80px 0;
    background-color: #F6F8F8;
  }
  .itme-top-grid {
    display: flex;
    gap: 32px;
    align-items: center;
  }
  .itme-top-grid .pdp-icon {
    width: 40%;
  }
  .itme-top-grid .pdp-text {
    width: 60%;
  }
  .itme-top-grid .pdp-text h3 {
    text-align: left;
    line-height: 46px;
  }
  .itme-top-grid .pdp-text h3::before {
    left: 0;
    transform: translateX(0%);
  }
  .itme-top-grid .pdp-text .item-text,
  .itme-top-grid .pdp-text .item-subtitle {
    text-align: justify;
    width: 100%;
  }
  .title-big {
    margin-bottom: 80px;
  }
  .item-title {
    font-weight: 500;
    font-size: 48px;
    line-height: 32px;
    color:var(--accent);
    position:relative;
    text-align:center;
    margin-bottom: 80px;
  }
  /* .item-title:before {
    content:'';
    position:absolute;
    bottom:-40px;
    left:50%;
    transform: translateX(-50%);
    width: 35%;
    height: 1px;
    background-color: var(--light);
  } */
  
  .item-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    width: 75%;
    margin:0 auto;
  }
  .product-spec {
    background-color: var(--accent);
    padding: 80px 0;
    position: relative;
    margin-top:56px;
  }
  .product-spec .bg-circle {
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    width: 700px;
    height: auto;
  }
  .spec-title {
    color: #F1F1F1;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    display: block;
    text-transform: uppercase;
    margin-bottom:32px;
  }
  .product-spec ul {
    list-style: none;
    width: 75%;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 32px;
  }
  .product-spec ul li {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #F1F1F1;
    border-radius: 16px;
    color: #202020;
    font-size: 20px;
    gap: 5px;
  }
  .product-spec ul li span:first-child {
    width: 40%;
  }
  .product-spec ul li span:last-child {
    width: 60%;
  }
  .product-spec a {
    margin: 0 auto;
  }
  #calc {
    margin-top: 80px;
  }
</style>