<template>
    <div class="product-item" :id="'product' + product.id">
      <div class="container">
        <div class="itme-top-grid">
            <div class="pdp-icon">
                <img :src="baseURL + '/' + product.image" alt="">
            </div>
            <div class="pdp-text">
                <h3 class="item-title">{{ $t(product.title) }}</h3>
                <span class="item-subtitle">{{ $t('description')}}</span>
                <p class="item-text">{{ $t(product.description) }}</p>
            </div>
        </div>
      </div>
      <div class="product-spec">
        <img class="bg-circle" src="../../../assets/images/circle-blue.png" alt="bg-img">
        <div class="container pos-r">
          <span class="spec-title">{{ $t('specifications') }}</span>
          <ul>
            <!-- <li><span>{{ $t('products.spec.title-1') }}</span><span>{{ $t(`products.spec${product.id}.title-1`) }}</span></li> -->
            <li><span>{{ $t('products.spec.title-2') }}</span><span>{{ $t(`products.spec${product.id}.title-2`) }}</span></li>
            <li><span>{{ $t('products.spec.title-3') }}</span><span>{{ $t(`products.spec${product.id}.title-3`) }}</span></li>
            <li><span>{{ $t('products.spec.title-4') }}</span><span>{{ $t(`products.spec${product.id}.title-4`) }}</span></li>
            <li><span>{{ $t('products.spec.title-5') }}</span><span>{{ $t(`products.spec${product.id}.title-5`) }}</span></li>
            <li><span>{{ $t('products.spec.title-6') }}</span><span>{{ $t(`products.spec${product.id}.title-6`) }}</span></li>
            <li><span>{{ $t('products.spec.title-7') }}</span><span>{{ $t(`products.spec${product.id}.title-7`) }}</span></li>
            <li><span>{{ $t('products.spec.title-8') }}</span><span>{{ $t(`products.spec${product.id}.title-8`) }}</span></li>
          </ul>
          <a class="btn btn-solid" @click="openPopup"  href="javascript:;">{{$t('order-now')}}</a>
        </div>
      </div>
    </div>
</template>

<script>
  import ProductOrderForm from '@/components/blocks/productItems/ProductOrderForm.vue'


export default {
  props: ['product'],
  components: {
    ProductOrderForm
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_URL
    }
  },
  methods: {
    openPopup() {
      this.$emit('open-modal', this.product.title);
      document.body.classList.add('shadow');
    },
  },
  }


</script>

<style>
#product .product-spec a.btn {
  text-transform: math-auto;
}
</style>
