import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductView from '../views/ProductView.vue'
import Certification from '../views/Certification.vue'
import Company from '../views/Company.vue'
import Safety from '../views/Safety.vue'
import Community from '../views/Community.vue'
import Press from '../views/Press.vue'
import Gallery from '../views/Gallery.vue'
import SingleAlbum from '../views/SingleAlbum'
import Careers from '../views/Careers.vue'
import Contact from '../views/Contact.vue'
import SingleNews from "../views/SingleNews.vue"
import SingleCareer from "../views/SingleCareer"
import CarieerForm from '@/components/blocks/CarieerForm.vue';

Vue.use(VueRouter)

const routes = [
    {
      path: '',
      name: 'home',
      component: HomeView
    },
    {
      path: '/product',
      name: 'product',
      component: ProductView
    },
    {
      path: '/company',
      name: 'company',
      component: Company
    },
    {
      path: '/certification',
      name: 'certification',
      component: Certification
    },
    {
      path: '/safety',
      name: 'safety',
      component: Safety
    },
    {
      path: '/community',
      name: 'community',
      component: Community
    },
    {
      path: '/press',
      name: 'press',
      component: Press,
    },
    {
      path: '/press/:id',
      name: 'press.single',
      component: SingleNews
    },

    {
      path: '/gallery',
      name: 'gallery',
      component: Gallery
    },
    {
      path: '/gallery/:id',
      name: 'gallery.single',
      component: SingleAlbum
    },
    {
      path: '/careers',
      name: 'careers',
      component: Careers
    },
    {
      path: '/career/:id',
      name: 'career.single',
      component: SingleCareer
    },
    {
      path: '/career/form/:id',
      name: 'career.form',
      component: CarieerForm
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    }

]

export default routes
