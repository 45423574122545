<template>
    <section id="single-news">
        <div class="container">
        <!-- <a href="javascript:;" @click="goBack"><i class="icon-arrow-left"></i></a> -->
        <div class="blog-item-single">
            <span class="blog-data">{{ moment(pressItem.updatedAt).format('Y-MM-D') }}</span>  
            <h4 v-html="pressItem.title[locale]"></h4>
            <div>
                <img :src="imagePath(pressItem.imagePath)" alt="blog-image-1">
                <p v-html="pressItem.text[locale]"></p>
            </div>
        </div>
    </div>
    </section>
</template>
<script>
import moment from 'moment'

export default {
  computed: {
    locale() {
      const lang = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1')
      if (lang === 'am' || lang === 'ru' || lang === 'en') {
        return lang
      } else {
        return 'am'
      }
    },
  },
  data() {
    return {
      pressItem: []
    }
  },
  methods: {
    imagePath(path) {
      return `${process.env.VUE_APP_API_URL}/${path}`
    },
    moment(dateTime) {
      return moment(dateTime)
    },
    goBack() {
      this.$router.go(-1); 
    },
  },
  created() {
    this.axios.get(`blog/${this.$route.params.id}`).then(res => {
      console.log(res.data);
      this.pressItem = res.data
    })
  }
}
</script>
<style>
    #single-news{
        padding-top:32px;
        background: linear-gradient(180deg, #1E6091 0%, 
                    rgba(50, 120, 145, 0.8) 20%, 
                    rgba(61, 132, 146, 0.6) 40%, 
                    rgba(73, 147, 146, 0.4) 60%, 
                    rgba(96, 174, 147, 0.2) 80%, 
                    rgba(118, 200, 147, 0) 100%);
    }
    .blog-item-single {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .blog-item-single .blog-data {
        font-size: 32px;
        margin-bottom:32px;
        color: var(--secondary);
    }
    .blog-item-single h4{
        font-size: 48px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 32px;
        font-weight: 500;
    }
    .blog-item-single > div {
        display: flex;
        gap: 32px;
    }
    .blog-item-single img {
        width: 50%;
        height: 490px;
        border-radius: 24px;
        object-fit: cover;
    }
    .blog-item-single p {
        margin-top: 32px;
        display: block;
    }
</style>