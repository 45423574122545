import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from "vue2-google-maps"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from "vue-i18n";
import loadLocaleMessages from "@/i18n";
import VueRouter from "vue-router";
import routes from "./router";

Vue.config.productionTip = false

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

Vue.use(VueAxios, axiosInstance)
Vue.use(VueI18n)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDDnxPgdrap2FYX0w42flCUycdMlFO1cpk",
    libraries: "places"
  }
});

const i18n =  new VueI18n({
  locale: (locale.trim() === 'am' || locale.trim() === 'ru' || locale.trim() === 'en') ? locale : 'am',
  fallbackLocale: 'am',
  messages: loadLocaleMessages()
  
})

console.log('this is locale', process.env.BASE_URL + locale)

const router = new VueRouter({
  mode: 'history',
  base: (locale.trim() === 'am' || locale.trim() === 'ru' || locale.trim() === 'en') ? process.env.BASE_URL + locale : process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 150 } };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: 'smooth' };
    }
  },
})

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')