<template>
    <section id="single-career">
        <div class="container">
            <div class="single-career-wrap">
                <h2 class="block-title"></h2>
                <div class="mt-80">test</div>
            </div>
        </div>
    </section>
</template>

<script>


    export default {
        
    }
</script>