<template>
    <section id="home-blog">
        <div class="container">
            <h2 class="block-title">{{$t('news')}}</h2>
                <div class="block-grid-3">
                    <div class="blog-item blog-item-1" v-for="(pressItem, index) of pressItems" v-if="index < 6" :key="index">
                    <img :src="imagePath(pressItem.imagePath)" alt="blog-image-1">
                    <span class="blog-data">{{ moment(pressItem.updatedAt).format('Y-MM-D') }}</span>
                    <h4 v-html="pressItem.title[locale]" />
                    <p v-html="truncateText(pressItem.text[locale], 300)"/>
                    <router-link :to="{name: 'press.single', params: {id: pressItem.id}}" class="simple-link">{{$t('read-more')}}
                    </router-link>
                    </div>
                </div>
            <router-link to="/press" class="show-more-btn btn btn-light">{{$t('read-more')}}</router-link>
        </div>
    </section>
</template>

<script>
import SingleNews from "@/views/SingleNews.vue";
import moment from 'moment'

export default {
  components: {SingleNews},
  computed: {
    locale() {
      const lang = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1')
      if (lang === 'am' || lang === 'ru' || lang === 'en') {
        return lang
      } else {
        return 'am'
      }
    },
  },
  data() {
    return {
      pressItems: []
    }
  },
  methods: {
    imagePath(path) {
      return `${process.env.VUE_APP_API_URL}/${path}`
    },
    moment() {
      return moment()
    },
    truncateText(text, limit) {
      if (text && text.length <= limit) {
        return text;
      } else if(text) {
        return text.slice(0, limit) + " ...";
      } else {
        return ''
      }
    },
  },
  created() {
    this.axios.get('blog').then(res => {
      this.pressItems = res.data
    })
  }
}
</script>

<style>
    .blog-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 16px;
    }
    .blog-item img {
        border-radius: var(--b-radius);
        width:100%;
        height: 300px;
        object-fit: cover;
    }
    .blog-item .blog-data {
        font-size: 24px;
        color: var(--light);
    }
    .blog-item p {
        font-size: 16px;
        line-height: 160%;
    }
    .blog-item a.simple-link {
        font-size: 14px;
        color: var(--light);
    }
    .show-more-btn {
        margin:0 auto;
        margin-top: 80px;
    }
</style>