<template>
  <div class="language">
    <img alt="language-icon" src="../assets/svg/icon-language-solud.svg"/>
    <div class="drop-lang">
      <a href="javascript:;" @click.prevent="setLocale('en')">En<i class="icon-flag-en"></i></a>
      <a href="javascript:;" @click.prevent="setLocale('ru')">Ru<i class="icon-flag-ru"></i></a>
      <a href="javascript:;" @click.prevent="setLocale('am')">Am<i class="icon-flag-am"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return {
      langs: ['en', 'am', 'ru']
    }
  },
  methods: {
    setLocale(locale) {
      window.location.replace(
          process.env.VUE_APP_URL + '/' + locale + this.removeStrings(window.location.pathname, this.langs),
      );
      this.$i18n.locale = locale
    },
    removeStrings(inputString, stringsToRemove) {
      const pattern = stringsToRemove.map(str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
      const regex = new RegExp(pattern, 'g');
      return this.removeRepeatedSlashes(inputString.replace(regex, ''));
    },
    removeRepeatedSlashes(inputString) {
      return inputString.replace(/\/{2,}/g, '/');
    }
  }
}

</script>
<style>
.language {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.drop-lang {
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--accent);
}

.language:hover .drop-lang {
  height: auto;
  padding: 15px 20px;
}

.language:hover .drop-lang > a {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.language:hover .drop-lang > a:not(:last-child) {
  margin-bottom: 14px;
}
</style>