<template>
  <section id="career">
    <div class="career-hero mb-80">
      <h2 class="title-big center white">{{ $t('current-vacancies') }}</h2>
    </div>
    <div class="container">
      <h3 class="block-title">{{ $t('open-positions') }}</h3>
      <!-- <p class="item-text w-75">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
        when an unknown printer took a galley of type and scrambled it to make a type 
        specimen book.
        </p> -->
      <div class="mt-80">
        <div class="open-position-wrap mb-32" v-for="careerItem of careerItems">
          <div class="open-position-item">
            <div>
              <p v-html="careerItem.title[locale]" />
              <router-link :to="{name: 'career.single', params: {id: careerItem.id}}" class="simple-link secondary">{{ $t('more-about-position') }}
                <i class="icon-arrow-right"></i>
          </router-link>
            </div>
            <router-link :to="{name: 'career.form', params: {id: careerItem.id}}" class="btn btn-light">{{ $t('apply')}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CarieerForm from '@/components/blocks/CarieerForm.vue';
import SingleCareer from '@/views/SingleCareer.vue';
export default {
  components: {
    CarieerForm,
    SingleCareer
  },
  computed: {
    locale() {
      return window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
    },
  },
  data() {
    return {
      careerItems: []
    }
  },
  created() {
    this.axios.get('career').then(res => {
      console.log(res.data);
      this.careerItems = res.data.reverse();
    })
  }
  
  
 }
 
</script>

<style scoped>
.career-hero {
  position: relative;
  background-image: url(../assets/images/small-hero.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 176px;
  line-height: 176px;
}
.career-hero:before{
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(271.77deg, #1E6091 32.72%, rgba(118, 200, 147, 0.24) 111.16%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.career-hero h2.title-big {
  margin-bottom:0;
  position: relative;
  z-index: 1;
}
.open-position-wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.open-position-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background-color: #ECF0F2;
  border-radius: 24px;
}
.open-position-item > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.open-position-item > div p{
  font-size: 24px;
}
.open-position-item > div a{
  font-size: 16px;
}
</style>