<template>
    <div id="career-form">
        <div class="container">
            <h2 class="title-big">Application form</h2>
            <form  action="https://submit-form.com/XftyApNYEn" method="post">
                <!-- <input type="hidden" name="_redirect" value="http://localhost:8080/en/careers" /> -->
                <h3 class="block-title">Personal information</h3>
                <div class="input-group">
                    <label for="name"> 
                        <span>Your Full Name<em></em> </span>
                        <input type="text" v-model="name"  placeholder="Enter Your Full Name Here" required/>
                    </label>
                    <label for="date"> 
                        <span>Your Date Of Birth<em></em> </span>
                        <input type="date" v-model="date"  placeholder="DD / MM / YYYY" required/>
                    </label>
                    <label for="email"> 
                        <span>Your Email Adress<em></em> </span>
                        <input type="email" v-model="email"  placeholder="Enter Your email Adress Here" required/>
                    </label>
                </div>
                <h3 class="block-title">education, experience</h3>
                <div class="input-group">
                    <label for="Education"> 
                        <span>Education<em></em> </span>
                        <select v-model="education"  required>
                            <option value="secondary-education">secondary education</option>
                            <option value="secondary-vocational-education">secondary vocational education</option>
                            <option value="higher-education">Higher Education</option>
                        </select>
                    </label>
                    <label for="Education"> 
                        <span>work experience<em></em> </span>
                        <select v-model="experience"  required>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                </div>
                <h3>CV/Resume</h3>
                <div class="input-group">
                    <label for="cv">Upload your CV or resume file</label>
                        <input
                        type="hidden"
                        id="photo"
                        v-model="cv"
                        role="uploadcare-uploader"
                        data-public-key="f1b9295cbeb6f0442de6"
                        />
                </div>
                <button class="btn btn-solid" type="submit">Submit</button>
            </form>
        </div>
    </div>
</template>

<script>
    
    

    export default {
  data() {
    return {
        name: '',
        date: '',
        email: '',
        education: '',
        experience: '',
        cv: '',
    };
  },
  
};


</script>

<style>
    #career-form {
        margin-top: 36px;
    }
    #career-form .input-group {
        margin-bottom: 46px;
    }
</style>