<template>
    <section id="single-albume">
        <div class="container">
            <span class="gallery-data">{{ moment(galleryItem.updatedAt).format('Y-MM-D') }}</span>
            <h4 v-html="galleryItem.title[locale]" />
            <div class="block-grid-3">
                <a ref="fancyboxLink" :href="imagePath(Item.path)" v-for="Item of galleryItem.galleryItems"><img :src="imagePath(Item.path)" alt="image" ></a>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    locale() {
      return window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
    },
  },
  data() {
    return {
        galleryItem: []
    }
  },
  methods: {
    initFancybox() {
      // Use this.$refs to access the element with the "ref" attribute
      $(this.$refs.fancyboxLink).fancybox();
    },
    imagePath(path) {
      return `${process.env.VUE_APP_API_URL}/${path}`
    },
    moment() {
      return moment()
    }
  },
  created() {
    
    this.axios.get(`gallery/${this.$route.params.id}`).then(res => {
      console.log(res.data);
      this.galleryItem = res.data;
    })
  }
}
</script>

<style>
    #single-albume {
        padding: 80px 0;
    }
    #single-albume .block-grid-3 img {
        width: 100%;
        height: 340px;
        object-fit: cover;
        border-radius: 16px;
    }
</style>