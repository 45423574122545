<template>
    <header>
      <div class="container">
        <div class="d-flex space-between align-center h-100 pos-r">
          <div class="logo-wrapp">
            <router-link to="/"><img alt="Vue logo" src="../assets/images/logo.svg"/></router-link>
          </div>
          <nav class="header-nav hidden-f-1000">
            <div class="header-link">
              <router-link to="/product" :class="{ active: $route.path === '/product' }">{{ $t('nav.linkProduct') }}</router-link>
              <div class="header-sub-link">
                <router-link :to="{name: 'product', hash: '#product1'}">{{ $t('products.product-1-title') }}</router-link>
                <router-link :to="{name: 'product', hash: '#product2'}">{{ $t('products.product-2-title') }}</router-link>
                <router-link :to="{name: 'product', hash: '#product3'}">{{ $t('products.product-3-title') }}</router-link>
                <router-link :to="{name: 'product', hash: '#product4'}">{{ $t('products.product-4-title') }}</router-link>
                <router-link :to="{name: 'product', hash: '#product5'}">{{ $t('products.product-5-title') }}</router-link>
                <router-link :to="{name: 'product', hash: '#product6'}">{{ $t('products.product-6-title') }}</router-link>
                <router-link :to="{name: 'product', hash: '#calc'}">{{ $t('foil-calculator') }}</router-link>
              </div>
            </div>
            <div class="header-link">
              <router-link to="/company" :class="{ active: $route.path === '/company' }">{{ $t('nav.linkCompany') }}</router-link>
              <div class="header-sub-link">
                <router-link to="/certification" :class="{ active: $route.path === '/certification' }">{{ $t('nav.linkCertification') }}</router-link>
                <router-link to="/safety" :class="{ active: $route.path === '/safety' }">{{ $t('nav.linkSafety') }}</router-link>
                <router-link to="/community" :class="{ active: $route.path === '/community' }">{{ $t('nav.linkCommunity') }}</router-link>
                <router-link to="/gallery" :class="{ active: $route.path === '/gallery' }">{{ $t('nav.linkGallery') }}</router-link>
                <router-link to="/careers" :class="{ active: $route.path === '/careers' }">{{ $t('nav.linkCareers') }}</router-link>
              </div>
            </div>
            <div class="header-link"><router-link to="/press" :class="{ active: $route.path === '/press' }">{{ $t('nav.linkPress') }}</router-link></div>
            <div class="header-link"><router-link to="/contact" :class="{ active: $route.path === '/contact' }">{{ $t('nav.linkContacts') }}</router-link></div>
          </nav>
          <nav class="header-nav-mobile show-1000" :class="{ 'show-menu': isMenuOpen }">
            <a href="javascript:;" @click="toggleMenu" class="mobile-menu-btn pos-r">
              <span></span>
              <span></span>
              <span></span>
            </a>
            <div class="menu-dropdown" >
              <div class="header-link pos-r">
                <router-link @click.native="toggleSubMenu" to="/product" :class="{ active: $route.path === '/product' }">{{ $t('nav.linkProduct') }} <i class="icon-angel-down"></i></router-link>
                <div class="header-mobile-sub-link" :class="{ 'show-sub-menu': isSubMenuOpen }">
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#product1'}">{{ $t('products.product-1-title') }}</router-link>
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#product2'}">{{ $t('products.product-2-title') }}</router-link>
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#product3'}">{{ $t('products.product-3-title') }}</router-link>
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#product4'}">{{ $t('products.product-4-title') }}</router-link>
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#product5'}">{{ $t('products.product-5-title') }}</router-link>
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#product6'}">{{ $t('products.product-6-title') }}</router-link>
                  <router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" :to="{name: 'product', hash: '#calc'}">{{ $t('foil-calculator') }}</router-link>
                </div>
              </div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/company" :class="{ active: $route.path === '/company' }">{{ $t('nav.linkCompany') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/certification" :class="{ active: $route.path === '/certification' }">{{ $t('nav.linkCertification') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/safety" :class="{ active: $route.path === '/safety' }">{{ $t('nav.linkSafety') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/community" :class="{ active: $route.path === '/community' }">{{ $t('nav.linkCommunity') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/press" :class="{ active: $route.path === '/press' }">{{ $t('nav.linkPress') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/gallery" :class="{ active: $route.path === '/gallery' }">{{ $t('nav.linkGallery') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/careers" :class="{ active: $route.path === '/careers' }">{{ $t('nav.linkCareers') }}</router-link></div>
              <div class="header-link"><router-link @click.native="isSubMenuOpen = false; isMenuOpen = false" to="/contact" :class="{ active: $route.path === '/contact' }">{{ $t('nav.linkContacts') }}</router-link></div>
            </div>

          </nav>
          <div class="header-right">
          <div class="header-contact">
            <a :href="'tel:' +37410231581"><i class="icon-phone"></i></a>
            <a href="mailto:armenal@armenal.am"><i class="icon-mail"></i></a>
          </div>
          <LanguageDrop/>
          </div>
          
    </div>
      </div>
    </header>
</template> 

<script>
import LanguageDrop from './LanguageDrop.vue';
export default {
  components : {
    LanguageDrop
  },
  data() {
    return {
      isMenuOpen: false,
      isSubMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleSubMenu() {
      this.isSubMenuOpen = !this.isSubMenuOpen;
    },
  },
}

</script>

<style>
  header {
    background-color: var(--accent);
    color: #fff;
    height: 64px;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
  .logo-wrapp {
    min-width: 143px;
    width:143px;
  }
  .logo-wrapp img {
    height: 46px;
  }
  .header-nav {
    display: flex;
    gap:54px;
    height: 100%;
    line-height: 64px;
  }
  header nav > div > a.active,
  header nav > div:hover > a {
    color: var(--secondary);
  }
  .header-link {
    position: relative;
  }
  .header-link .header-sub-link {
    position: absolute;
    top:100%;
    left: 0;
    background-color: #fff;
    border-radius:8px ;
    padding: 16px;
    color: var(--dark);
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: max-content;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    filter: drop-shadow(1px 3px 4px rgba(0, 0, 0, 0.623));
  }
  .header-link:hover > .header-sub-link {
    opacity: 1;
    height: auto;
    visibility: visible;
    transition: all 0.3s ease;
  }
  .header-link .header-sub-link:before {
    content: '';
    position:absolute;
    bottom: 100%;
    left:25px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
  }
  .header-link .header-sub-link > a {
    line-height: 120%;
  }
  .header-link .header-sub-link > a:last-child {
    position: relative;
    padding-top: 16px;
  }
  .header-link .header-sub-link > a:last-child::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    background-color: var(--dark);
  }
  .header-link .header-sub-link > a:hover {
    color: var(--secondary);
    transition: all 0.3s ease;
  }
  .header-contact,
  .header-right {
    display: flex;
    gap: 25px;
    align-items: center;
  }
  .header-contact a { 
    color: #fff;
  }
</style>