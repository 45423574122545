<template>
    <div class="order-form-popup">
        <h2 class="block-title left">{{ $t('product-form.title') }}<button @click="closePopup" class="close-popup">X</button></h2>
        <p class="block-subtitle left">{{ $t('product-form.subtitle') }}</p>
        <form action="" id="pdp-order-form">
            <h3>{{ $t('product-form.form-title1') }}</h3>
            <div class="input-group">   
                <label for="company"> 
                    <span>{{$t('product-form.input2-title')}}<em></em> </span>
                        <input type="text"  name="company" :placeholder="$t('product-form.input2-placeholder')" />
                </label>
                <label for="name"> 
                    <span>{{ $t('product-form.input1-title') }}<em></em> </span>
                    <input type="text" name="name"  :placeholder="$t('product-form.input1-placeholder')" required/>
                </label>
             
              
              <label for="email"> 
                <span>{{ $t('product-form.input4-title') }}<em></em> </span>
                    <input type="email" required name="email" :placeholder="$t('product-form.input4-placeholder')" />
              </label>
              <label for="phone"> 
                <span>{{$t('product-form.input5-title')}}<em></em> </span>
                    <input type="tel" required name="phone" :placeholder="$t('product-form.input5-placeholder')" />
              </label>
              <label for="country"> 
                {{$t('product-form.input12-title')}}
                    <textarea name="other"  :placeholder="$t('product-form.input12-placeholder')"></textarea>
              </label>
            </div>
            <div class="btn-group">
                <div>
                    <button type="submit" class="btn btn-green-solid">{{ $t('apply') }}</button>
                </div>
                <span><sup><em></em></sup>— {{ $t('required') }}</span>
            </div>
        </form>    
    </div>
</template>

<script>
export default {
  data() {
    return {
      companyType: '',
      material: '',
      grade: '',
      isDisabled: true,
    }
  },
  methods: {
    closePopup() {
        this.$emit('close-modal', false);
        document.body.classList.remove('shadow');
    }
},
}
</script>

<style>
    .order-form-popup {
        position: fixed;
        top: 64px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 750px;
        width:100%;
        padding: 25px 40px;
        background-color: #fff;
        z-index: 150;
        overflow-y: auto;
        height: calc(100vh - 64px);
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .order-form-popup h2 {
        margin-bottom: 12px;
        position: relative;
    }
    .order-form-popup h2 .close-popup {
        position: absolute;
        right:10px;
        top:8px;
        font-size: 18px;
        font-weight: 700;
        background: transparent;
    }
    #pdp-order-form {
        margin-top: 16px;
    }
    #pdp-order-form h3 {
        font-size: 16px;
    }
    #pdp-order-form .input-group {
        margin-top: 20px;
        margin-bottom: 26px;
    }
    #pdp-order-form .input-group input,
    #pdp-order-form .input-group select,
    #pdp-order-form .input-group textarea {
        max-width: 480px;
    }
    #pdp-order-form .input-group select option {
        text-transform: capitalize;
    }
    #pdp-order-form .btn-group {
        display: flex;
        flex-direction: column;
        gap:26px;
        font-size: 14px;
        max-width: 480px;
    }
    #pdp-order-form .btn-group div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #pdp-order-form .btn-group span em {
        background-image: url(../../../assets/svg/icon-requared.svg);
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 5px;
    }
    .robot input {
        background: #F6F8F8;
        opacity: 0.7;
        box-shadow: 0px 1px 8px 1px #b9b9b9;
        border-radius: 4px;
        width: 20px;
        height: 20px;
    }
</style>