<template>
    <section id="gallery">
      <div class="container">
        <h2 class="block-title title-white">{{ $t('gallery') }}</h2>
        <div class="block-grid-3">
          <div class="gallery-item" v-for="galleryItem of galleryItems">
            <img :src="imagePath(galleryItem.imageUrl)" alt="blog-image-1">
            <span class="gallery-data">{{ moment(galleryItem.updatedAt).format('Y-MM-D') }}</span>
            <h4 v-html="galleryItem.title[locale]" />
            <router-link :to="{name: 'gallery.single', params: {id: galleryItem.id}}" class="simple-link">Read More</router-link>
          </div>
        </div>
      </div>
    </section>
  </template>

<script>
import SingleAlbum from "@/views/SingleAlbum.vue"
import moment from 'moment'

export default {
  components: {SingleAlbum},
  computed: {
    locale() {
      return window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
    },
  },
  data() {
    return {
        galleryItems: []
    }
  },
  methods: {
    imagePath(path) {
      return `${process.env.VUE_APP_API_URL}/${path}`
    },
    moment() {
      return moment()
    }
  },
  created() {
    // TODO move to gallery page
    this.axios.get('gallery').then(res => {
      this.galleryItems = res.data;
      console.log(this.galleryItems);
    })
  }
}
</script>
<style>
#gallery {
  position: relative;
  padding-top: 120px;
}

#gallery:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, #1E6091 0%,
  rgba(50, 120, 145, 0.8) 20%,
  rgba(61, 132, 146, 0.6) 40%,
  rgba(73, 147, 146, 0.4) 60%,
  rgba(96, 174, 147, 0.2) 80%,
  rgba(118, 200, 147, 0) 100%);
  z-index: -1;
}

#gallery .block-grid-3 {
  margin-top: 80px;
}
.gallery-item img {
    border-radius: 16px;
    width:100%;
    height: 340px;
    object-fit: cover;
}
.gallery-data {
    font-size: 24px;
    color: var(--light);
    margin:16px 0;
    display: block;
}
.gallery-item h4 {
    color:#003763 ;
    font-size: 24px;
    font-weight: 500;
}
</style>