<template>
  <section id="contact">
    <img src="../assets/images/company-hero.png" alt="hero-image">
    <div class="contact-hero">
      <div class="container">
        <div class="contact-hero-content">
          <h2 class="title-big text-center">{{ $t('nav.linkContacts') }}</h2>
          
        </div>

      </div>
    </div>
    <div class="container">
      <div class="contact-info">
        <div class="contact-image"><img src="../assets/images/contact-image.png" alt="contact image"></div>
        <div class="contact-info-text">
          <h3>{{$t('armenal')}}</h3>
          <div>
            <a href="mailto:armenal@armenal.am">E-mail: armenal@armenal.am</a>
            <span>{{ $t('general-department') }}</span>
          </div>
          <a :href="telLink">{{ $t('tel') }} {{$t('contact-number')}}</a>
          <p>{{$t('address')}}: {{$t('address-line1')}}, {{$t('address-line2')}}</p>
                                
        </div>
      </div>
    </div>

    
    <gmap-map
        :zoom="15"    
        :center="center"
        style="width:100%;  height: 600px;"
      >
      <gmap-marker
        :key="index"
        v-for="(m, index) in locationMarkers"
        :position="m.position"
        :title="m.labelContent"
      />

      <gmap-info-window :opened="showMarkerInfo" :position="markerInfoPosition">
        <div class="marker-label">{{ markerInfo }}</div>
      </gmap-info-window>
    </gmap-map>
  </section>
</template>
<script>
  export default {
    computed: {
    telLink() {
      return `tel:${this.$t('contact-number')}`;
    }
  },
  name: "AddGoogleMap",
  data() {
    return {
      showMarkerInfo: true,
      markerInfo: 'Armenal',
      markerInfoPosition: {
        lat: 40.217300,
        lng: 44.514529,
      },
      center: { 
        lat: 40.215797,
        lng: 44.514529
      },
      locationMarkers: [
        {
            position: {
              lat: 40.215797,
              lng: 44.514529,
            },
        }
      ],
      locPlaces: [],
      existingPlace: null
    };
  },
  // mounted() {
  //   this.locateGeoLocation();
  // },
 
  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng()
        };
        this.locationMarkers.push({ position: marker, labelContent: 'asdasd' });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    // locateGeoLocation: function() {
    //   navigator.geolocation.getCurrentPosition(res => {
    //     this.center = {
    //       lat: res.coords.latitude,
    //       lng: res.coords.longitude
    //     };
    //   });
    // }
  }
};
</script>


<style scoped>
#contact {
  position: relative;
}

#contact > img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 500px;
}

.contact-hero-content {
  width: 60%;
  padding-top: 240px;
}

.contact-hero-content > h2 {
  margin-bottom: 160px;
}

.contact-hero-text:before {
  content: '';
  position: absolute;
  top: -32px;
  width: 60%;
  height: 1px;
  background-color: var(--light);
}

.contact-info {
  margin-top: 160px;
  display: flex;
  gap: 32px;
}

.contact-info .contact-image {
  width: 65%;
}

.contact-info .contact-image img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 18px;
}

.contact-info-text {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.contact-info-text h3 {
  font-size: 32px;
  font-weight: 400;
  color: var(--light);
  text-transform: uppercase;
}

.contact-info-text div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact-info-text a {
  font-size: 24px;
  text-decoration: underline;
  font-weight: 500;

}

.contact-info-text span {
  font-size: 16px;
}

.contact-info-text p {
  font-size: 24px;
  font-weight: 500;
}
.vue-map-container {
  margin-top: 160px;
}
</style>
