<template>
  <div class="hero-video">
      <video width="1800" height="624" autoplay muted loop style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;object-fit: cover;z-index:-1;">
     <source src="../../assets/video/hero-video-v2.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="container hero-content">
        
        <div class="hero-slider-left">
          <span>{{ $t('armenal') }}</span>
          <h1>{{ $t('hero.title-1') }}</h1>
          <p>{{ $t('hero.slogan-1') }}</p>
          <div class="hero-buttons d-flex gap-32">
            <a class="btn btn-solid" @click="openPopup"  href="javascript:;">{{$t('order-now')}}</a>
            <router-link to="/company" class="btn btn-light"><span>{{ $t('more-about-us') }}</span><i class="icon-arrow-right"></i></router-link>
          </div>
        </div>  
      </div>
      <ProductOrderForm  v-if="isPopupOpen" @close-modal="closeModal"/>
  </div>
</template>
<script>
  import ProductOrderForm from '@/components/blocks/productItems/ProductOrderForm.vue'


export default {
  
  components: {
    ProductOrderForm
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_URL,
      isPopupOpen: false,
    }
  },
  // created() {
  //   document.getElementsByTagName('body')[0].addEventListener('click', (event) => {
  //     const hasChild = event.target.querySelector(".order-form-popup") != null;
  //     if (hasChild) {
  //       this.closeModal()
  //     }
  //   })
  // },
  methods: {
    openPopup() {
      this.isPopupOpen = true
      document.body.classList.add('shadow');
    },
    closeModal() {
      this.isPopupOpen = false
      document.body.classList.remove('shadow');
    }
  },
}


</script>
<style>
  .hero-video {
    height: 624px;
    width: 100%;
    padding-bottom: 60px;
    z-index: 5;
    position: relative;
  }
  .hero-video::before {
    content: '';
    background: var(--accent);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    mix-blend-mode: multiply;
    z-index: 2;
  }
  .hero-video video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .hero-content {
    position:relative;
    z-index: 4;
    display: flex;
    align-items: flex-end;
  }
  .hero-slider-left span,
  .hero-slider-left h1,
  .hero-slider-left p {
    color:#fff;
  }
  .hero-slider-left{
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      gap:32px;
      color: #fff;
      max-width: 800px;
    }
    .hero-slider-left > span {
      font-size: 24px;
      text-transform: uppercase;
    }
    .hero-slider-left > h1 {
      font-size: 56px;
      text-transform: capitalize;
    }
    .hero-slider-left > p {
      font-size: 32px;
      text-transform: capitalize;
      text-align: left;
    }
</style>