<template>
  <div>
  <div class="home-wrap">
    <HeroSlider />
    <div class="container">
      <div class="home-section-top">
        
        <HomeProduct />
      </div>
    </div>
  </div>
  <HomeLogistic />
  <HomeCategory />
  <HomeNews />
  <ProductOrderForm  v-if="isPopupOpen" @close-modal="closeModal"/>
</div>
</template>

<script>
import HeroSlider from '@/components/blocks/HeroSlider.vue';
import HomeProduct from '@/components/blocks/HomeProduct.vue';
import HomeLogistic from '@/components/blocks/HomeLogistic.vue';
import HomeCategory from '@/components/blocks/HomeCategories.vue';
import HomeNews from '@/components/blocks/HomeNews.vue';
import ProductOrderForm from '@/components/blocks/productItems/ProductOrderForm.vue'
export default {
  components: {
    HeroSlider,
    HomeProduct,
    HomeLogistic,
    HomeCategory,
    HomeNews,
    ProductOrderForm,
  },data() {
    return {
      isPopupOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isPopupOpen = true;
    },
    closeModal() {
      this.isPopupOpen = false
    }
  },
}

</script>

<style scoped>
.home-wrap {
  background: rgb(30,96,145);
  background: linear-gradient(180deg, #1E6091 0%, rgba(50, 120, 145, 0.8) 20%, rgba(61, 132, 146, 0.6) 40%, rgba(73, 147, 146, 0.4) 60%, rgba(96, 174, 147, 0.2) 80%, rgba(118, 200, 147, 0) 100%);
}

</style>
