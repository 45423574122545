<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Footer
  },
  created() {

  },
  methods: {
    
  }
}

</script>

<style>
  @import './assets/style.css';
  @import './assets/responsive.css';
</style>
