<template>
    <section class="calculator" id="calc">
      <div class="container d-flex align-center d-column">
        <h2 class="block-title">{{ $t('foil-calculator') }}</h2>
        <p class="block-subtitle">{{ $t('calc-description') }}</p>
        <div class="calc-form-wrapp">
          <form action="" id="calc-form">
            <div class="input-group">
              <label> 
                {{ $t('roll-diameter') }} /D/
                <p>
                  <input v-model="number1" type="text" name="Outside Diameter" :placeholder=" $t('roll-diameter-placeholder')" />
                  <span class="dimantion">{{$t('mm')}}</span>
                </p>
              </label>
              <label> 
                {{ $t('roll-inside-diameter') }} /d/
                <p>
                  <select v-model="number2">
                    <option value="152.4">152.4</option>
                    <option value="150">150</option>
                    <option value="76.2">76.2</option>
                  </select> 
                  <!-- <input v-model="number2" type="number" name="Inside Diameter" :placeholder="$t('roll-inside-diameter-placeholder')"/> -->
                  <span class="dimantion">{{$t('mm')}}</span>
                </p>
              </label>
              <label> 
                {{ $t('аlloy material') }}
                <p>
                  <select v-model="selectedOption">
                    <option value="2.71">8011А</option>
                    <option value="2.74">8006</option>
                    <option value="2.73">3003</option>
                    <option value="2.71">1200</option>
                  </select> 
                  <!-- <input v-model="number2" type="number" name="Inside Diameter" :placeholder="$t('roll-inside-diameter-placeholder')"/> -->
                  <!-- <span class="dimantion">{{$t('mm')}}</span> -->
                </p>
              </label>
              <label> 
                {{$t('thickness-of-material')}} /T/
                <p>
                  <input v-model="number3" type="number" name="Thickness" :placeholder="$t('thickness-of-material-placeholder')"/>
                  <span class="dimantion">{{$t('mm')}}</span>
                </p>
              </label>
              <label> 
                  {{$t('material-lenght')}} / W/
                  <p>
                    <input v-model="number4" type="number" name="Lenght" :placeholder="$t('material-lenght-placeholder')"/>
                    <span class="dimantion">{{$t('mm')}}</span>
                  </p>
                </label>
              
              <div class="btn-group">
                <a href="javascript:;" @click="calculateResult" class="btn btn-green-solid" >{{$t('calculate')}}</a>
                <button  class="btn btn-green-light" type="reset">{{ $t('reset') }}</button>
              </div>
              <div class="show-result">
                <label> 
                  {{$t('roll-weight')}} /M/
                  <p>
                    <input v-model="result" type="number" :readonly="true" name="Weight" readonly/>
                    <span class="dimantion">{{$t('kg')}}</span>
                  </p>
                </label>
                <!-- <label> 
                  {{$t('roll-length')}} /M/
                  <p>
                    <input v-model="resultLenght" type="number" :readonly="true" name="Length" readonly/>
                    <span class="dimantion">{{$t('m')}}</span>
                  </p>
                </label> -->
              </div>
              
            </div>
          </form>
          <div class="calc-image-wrap">
            <img src="../../assets/images/icon-calc.png" alt="icon-calculator">
          </div>
          <!-- <div class="print-btn">
            <button @click="printContent"><i class="icon-print"></i> {{ $t('print') }}</button>
          </div> -->
        </div>  
      </div>
    </section>
</template>

<script>
  export default {
    data() {
    return {
      number1: null,
      number2: null,
      number3: null,
      number4: null,
      result: null,
      // resultLenght: null,
      selectedOption: null,
      pi: Math.PI,
    }
  },
  methods: {
    printContent() {
      let printContents = document.getElementById("calc-form").innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    calculateResult() {
      const num1 = parseFloat(this.number1).toFixed(2);
      const num2 = parseFloat(this.number2).toFixed(2);
      const num3 = parseFloat(this.number3).toFixed(2);
      const num4 = parseFloat(this.number4).toFixed(2);
      const num5 = parseFloat(this.selectedOption * 0.96).toFixed(2);
      const num6 = parseFloat(this.selectedOption).toFixed(2);
      if (!isNaN(num1) && !isNaN(num2) && !isNaN(num3) && !isNaN(num4) && !isNaN(num5)) {
        const weightMath = Number((num5 * ((Math.PI / 4) * ((num1 * num1) - (num2 * num2)) * num4) / 1000000 ))
        // const lenghtMath = Number(weightMath * 1000 / (num4 * num3 * num6))
        const Result = weightMath
        // const ResultLenght = lenghtMath
        this.result = Math.round(Result)
        // this.resultLenght = Math.round(ResultLenght)
      } else {
        this.result = null;
      }
    }
  }
}
</script>
<style>
 .calc-form-wrapp {
  margin-top:32px;
  width: 950px;
  border-radius: 16px;
  padding: 40px 40px 100px 40px;
  -webkit-box-shadow: 2px 3px 6px 0px #838383;
  box-shadow: 2px 3px 6px 0px #838383;
  display: flex;
  gap: 50px;
  position: relative;
 }
 .calc-form-wrapp > div.print-btn {
  position: absolute;
  bottom: 37px;
  margin-top: 0px;
 }
 .calc-form-wrapp > div.print-btn button {
  border: none;
  background-color: #fff;
  color:#578DC2 ;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
 }
 .calc-form-wrapp > div,
 .calc-form-wrapp > form {
  width: 50%;
 }
 .calc-form-wrapp > div {
  margin-top: 72px;
 }
 .calc-form-wrapp form * {
  color:#202020;
 }
 
 form .radio-group {
  display: flex;
  gap: 16px;
 }

  [type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #578DC266;
    border-radius: 100%;
    background: #578DC266;
;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #578DC2;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.show-result input {
  width: 50%!important;
  background: #e9e9e9;
}

</style>