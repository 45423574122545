<template>
  <section id="certification">
    <div class="doc-hero mb-80">
      <h2 class="title-big">{{ $t('nav.linkCertification') }}</h2>
    </div>
    <div class="container">
      <p class="item-text w-80">{{ $t('certefication-content-1') }}</p>
      <!-- <p class="item-text w-80">{{ $t('certefication-content-2') }}</p> -->
      <!-- <div class="pdf-grid block-grid-3 mt-80">
        <div class="pdf-item" v-for="certificate of certificates">
          <a :href="certificate.pdfUrl" target="_blank">{{ certificate.name }}</a>
          <vue-pdf-embed class="pdf-previewer" :source="'/'+certificate.pdfUrl"/>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      certificates: [
        {
          name: '14001-AE-RUS-FINAS',
          pdfUrl: 'pdf/pdf-en/iso9001en.pdf'
        },
        {
          name: '14001-AE-RUS-FINAS',
          pdfUrl: 'pdf/pdf-en/iso9001en.pdf'
        },
        {
          name: '14001-AE-RUS-FINAS',
          pdfUrl: 'pdf/pdf-en/iso9001en.pdf'
        },
        {
          name: '14001-AE-RUS-FINAS',
          pdfUrl: 'pdf/pdf-en/iso9001en.pdf'
        },
        {
          name: '14001-AE-RUS-FINAS',
          pdfUrl: 'pdf/pdf-en/iso9001en.pdf'
        },
        {
          name: '14001-AE-RUS-FINAS',
          pdfUrl: 'pdf/pdf-en/iso9001en.pdf'
        }
      ]

    };
  }
};

</script>


<style scoped>
.doc-hero {
  background-image: url(../assets/images/pdf-hero.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.doc-hero h2 {
  text-align: center;
  padding: 36px 0;
}

.pdf-item {
  height: 600px;
  background-color: #E7EEEF;
  border-radius: 16px;
  padding: 32px;
}

.pdf-previewer {
  max-height: 100%;
  overflow-y: auto;
}

</style>