<template>
    <footer>  
    <div class="container">
        <div class="footer-wrap">
            <div class="footer-top">
                <div class="footer-links-wrap footer-info">
                    <img src="../assets/images/logo-footer.svg" alt="logo">
                   
                    <div class="contact-info">
                        <h3><router-link to="/contact" :class="{ active: $route.path === '/contact' }">{{ $t('nav.linkContacts') }}</router-link></h3>
                        <ul>
                            <li>
                                <a href="mailto:armenal@armenal.am">E-mail: armenal@armenal.am</a>
                                <span>{{ $t('general-department') }}</span>
                            </li>
                            <li>
                                <a :href="telLink">{{ $t('tel') }} {{$t('contact-number')}}</a>
                            </li>
                            <li>
                                <p>{{$t('address')}}: {{$t('address-line1')}}, </p>
                                <p> {{$t('address-line2')}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="footer-links-wrap footer-links">
                    <h3 @click="toggleMenu1">{{ $t('nav.linkProduct') }} <i class="icon-angel-down show-1000"></i></h3>
                    <ul :class="{ 'show-menu': isMenuOpen1 }">
                        
                        <li><router-link to="/product" >{{ $t('aluminium-foil') }}</router-link></li>
                        <li><router-link to="/product/#calc">{{ $t('foil-calculator') }}</router-link></li>
                    </ul>
                    <h3 @click="toggleMenu2"><a>{{ $t('company') }}</a><i class="icon-angel-down show-1000"></i></h3>
                    <ul :class="{ 'show-menu': isMenuOpen2 }">
                        <li><router-link to="/company">{{ $t('our-history') }}</router-link></li>
                        <li><router-link to="/company/#our-value">{{ $t('our-value') }}</router-link></li>
                        <li><router-link to="/company/#our-team">{{ $t('about.corporate-governance') }}</router-link></li>
                    </ul>
                </div>
                <div class="footer-links-wrap footer-links">
                    <h3><router-link to="/certification">{{ $t('nav.linkCertification') }}</router-link></h3>
                    <h3 @click="toggleMenu3"><a href="/safety">{{ $t('nav.linkSafety') }}</a><i class="icon-angel-down show-1000"></i></h3>
                    <ul :class="{ 'show-menu': isMenuOpen3 }">
                        <li><router-link to="/safety/#healthy">{{$t('safety.content2-title')}}</router-link></li>
                        <li><router-link to="/safety/#environ">{{$t('safety.content5-title')}}</router-link></li>
                    </ul>
                    <h3 @click="toggleMenu4"><a>{{ $t('nav.linkCommunity') }}</a></h3>
                    <ul :class="{ 'show-menu': isMenuOpen4 }">
                        <li><router-link to="/community#yourth-council">{{$t('youth-council')}}</router-link></li>
                        <li><router-link to="/community#for-work">{{$t('work-council')}}</router-link></li>
                    </ul>
                </div>
                <div class="footer-links-wrap footer-links">
                    <h3 @click="toggleMenu5">{{ $t('nav.linkPress') }}<i class="icon-angel-down show-1000"></i></h3>
                    <ul :class="{ 'show-menu': isMenuOpen5 }">
                        <li><router-link to="/press">{{$t('press-releases')}}</router-link></li>
                        <li><router-link to="/gallery">{{ $t('nav.linkGallery') }}</router-link></li>
                    </ul>
                    <h3><router-link to="/careers">{{ $t('nav.linkCareers') }}</router-link></h3>
                    <!-- <ul :class="{ 'show-menu': isMenuOpen6 }">
                        <li><a>Candidate pool</a></li>
                        <li><a>"New Generation" Programme</a></li>
                        <li><a>For Students</a></li>
                        <li><a>For Schoolchildren</a></li>
                        <li><a>Open Positions</a></li>
                    </ul> -->
                </div>
            </div>
            <div class="footer-copyright">
                <p>Copyright  ©  {{ currentYear }} {{$t('copyright')}}</p>
            </div>
        </div>
    </div>
    </footer>
</template>  

<script>
export default {
  computed: {
    telLink() {
      return `tel:${this.$t('contact-number')}`;
    },
    currentYear() {
      return new Date().getFullYear();
    }
},
data() {
    return {
      isMenuOpen1: false,
      isMenuOpen2: false,
      isMenuOpen3: false,
      isMenuOpen4: false,
      isMenuOpen5: false,
    };
  },
  methods: {
    toggleMenu1() {
      this.isMenuOpen1 = !this.isMenuOpen1;
    },
    toggleMenu2() {
      this.isMenuOpen2 = !this.isMenuOpen2;
    },
    toggleMenu3() {
      this.isMenuOpen3 = !this.isMenuOpen3;
    },
    toggleMenu4() {
      this.isMenuOpen4 = !this.isMenuOpen4;
    },
    toggleMenu5() {
      this.isMenuOpen5 = !this.isMenuOpen5;
    },
    
  },
}
</script>

<style>
    footer {
        background-color: var(--accent);
        color: #fff;
        padding-top: 32px;
        margin-top:160px;
        position: relative;
    }
    footer > img {
        position: absolute;
        top: 0;
        right: 100px;
        width: 700px;
        height: auto;
    }
    .footer-top{
      display: flex;
      justify-content: space-between;
      gap:10px;  
    }
    .footer-top h3 {
        font-size: 20px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }
    .footer-top ul {
        margin-bottom:40px;
    }
    .footer-top ul li {
       margin-bottom: 16px; 
       text-transform: capitalize;
    }

    .footer-info img {
        margin-bottom: 15px;
    }
    .social-links ul {
        display:flex;
        gap:15px;
        margin-bottom: 20px;
        margin-top:15px;
    }
    .contact-info ul li {
        margin-bottom: 32px;
    }
    .contact-info ul li span {
        display: block;
        font-size:14px;
        font-weight: 300;
        margin-top:4px;
    }
    .contact-info ul li a {
        font-size: 20px;
        font-weight: 500;
        text-transform: math-auto;
    }
    
    .footer-copyright {
        margin-top: 40px;
        padding-bottom: 32px;
    }
    .footer-copyright p {
        font-size: 16px;
        text-align: center;
    }
</style>